/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    em: "em",
    ol: "ol",
    li: "li",
    strong: "strong",
    code: "code",
    pre: "pre",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "sorting-vector",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sorting-vector",
    "aria-label": "sorting vector permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sorting Vector"), "\n", React.createElement(_components.p, null, "and a part of it in Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "Sorting is defined as putting the sequence of elements into an ", React.createElement(_components.em, null, "order"), ". The order can be ascending or descending or can be decided using a comparison function."), "\n", React.createElement(_components.p, null, "Sorting is very useful in many applications because it can help in"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Searching for a given value efficient."), "\n", React.createElement(_components.li, null, "Finding the median of the sequence."), "\n", React.createElement(_components.li, null, "Merging the sequences."), "\n", React.createElement(_components.li, null, "Determining k", React.createElement("sup", null, "th"), " largest and smallest elements\netc."), "\n"), "\n", React.createElement(_components.p, null, "Hence, sorting is one of the most important algorithms."), "\n", React.createElement(_components.p, null, "But here, in this article, we will not discuss the algorithm itself. We will learn how to use functions provided in Rust's standard crate to sort the vector."), "\n", React.createElement(_components.h2, {
    id: "sort-method",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sort-method",
    "aria-label": "sort method permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "sort() method"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "sort()")), " method is used to sort the whole array. It is ", React.createElement(_components.strong, null, "stable sorting"), " algorithm, inspired by ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Timsort"
  }, "Timsort")), "\n", React.createElement(_components.p, null, "For Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn main() {\n    let mut vecky = vec![10, 9, 8, 7, 6, 5, 4, 3, 2, 1];\n    vecky.sort();\n    println!(\"{:?}\", vecky);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity (Average case) : O(n log(n) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O (n)")), "\n", React.createElement(_components.h2, {
    id: "sort_unstable-method",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sort_unstable-method",
    "aria-label": "sort_unstable method permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "sort_unstable() method"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "sort_unstable()")), " method, as suggested by name, is unstable sorting method, and can provide better performance than stable sort in some cases. It uses pattern defeating quick sort algorithm to sort the elements."), "\n", React.createElement(_components.p, null, "For Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn main() {\n    let mut vecky = vec![10, 9, 8, 7, 6, 5, 4, 3, 2, 1];\n    vecky.sort_unstable();\n    println!(\"{:?}\", vecky);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity (Average case) : O(n log(n) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O ( log (n) )")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " Although the time complexity is similar, ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "sort_unstable()")), " can be faster than stable sort."), "\n", React.createElement(_components.h2, {
    id: "sort-a-slice-of-a-vector",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sort-a-slice-of-a-vector",
    "aria-label": "sort a slice of a vector permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sort a slice of a Vector"), "\n", React.createElement(_components.p, null, "We can use both ", React.createElement(_components.code, null, "sort()"), " and ", React.createElement(_components.code, null, "sort_unstable()"), " to sort a slice of a vector. We simply have to use slice of the vector instead of vector itself. For example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "vecky[0..5].sort();\n")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "vecky[0..5].sort_unstable();\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[6, 7, 8, 9, 10, 5, 4, 3, 2, 1]"), "\n"), "\n", React.createElement(_components.h2, {
    id: "sort_by-custom-key",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sort_by-custom-key",
    "aria-label": "sort_by custom key permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "sort_by() custom key"), "\n", React.createElement(_components.p, null, "To sort a vector using our custom function, we have to use ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "sort_by()")), " method. We can simply provide a method to use as function in this."), "\n", React.createElement(_components.p, null, "For example, to sort a vector in descending order, we can define a compare function"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn compare(a: &i32, b: &i32) ->std::cmp::Ordering{\n    if a<b {\n        return std::cmp::Ordering::Greater;\n    }\n    if a==b{\n        return  std::cmp::Ordering::Equal;\n    }\n    return std::cmp::Ordering::Less;\n}\n")), "\n", React.createElement(_components.p, null, "and use it"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn main() {\n    let mut vecky = vec![1, 2, 3, 4, 5, 6, 7, 8, 9, 10];\n    vecky[0..5].sort_by(compare);\n    println!(\"{:?}\", vecky);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[5, 4, 3, 2, 1, 6, 7, 8, 9, 10]"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " 1. The compare function must return value of ", React.createElement(_components.code, null, "std::cmp::Ordering"), " type.\n2. We can alternatively use ", React.createElement(_components.code, null, "sort_by(|a, b| compare(a, b))"), " in case you need it."), "\n", React.createElement(_components.h2, {
    id: "sort_unstable_by",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sort_unstable_by",
    "aria-label": "sort_unstable_by permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "sort_unstable_by()"), "\n", React.createElement(_components.p, null, "We can use ", React.createElement(_components.code, null, "sort_unstable_by()"), " in similar manner. For example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn compare(a: &i32, b: &i32) ->std::cmp::Ordering{\n    if a<b {\n        return std::cmp::Ordering::Greater;\n    }\n    if a==b{\n        return  std::cmp::Ordering::Equal;\n    }\n    return std::cmp::Ordering::Less;\n}\n\nfn main() {\n    let mut vecky = vec![1, 2, 3, 4, 5, 6, 7, 8, 9, 10];\n    vecky[0..5].sort_unstable_by(compare);\n    println!(\"{:?}\", vecky);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[5, 4, 3, 2, 1, 6, 7, 8, 9, 10]"), "\n"), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Sorting is putting the sequence of elements into an ", React.createElement(_components.em, null, "order"), ", and is a very commonly used function.\nIn this article, we saw how to sort a vector using both stable sort and unstable sort and also, how to sort using a compare function in Rust Language."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Sorting a Vector - Rust Programming",
    description: "Sorting is putting the sequence of elements into an order. In this article, we will see how to sort a vector using both stable sort and unstable sort and also, how to sort using a compare function in Rust Language."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
